import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import InputField from "../components/InputField";
import SubmitButton from "../components/SubmitButton";
import "../pearllion.css";
class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      serverText: "",
      username: "",
      password: "",
      serverurl: "",
      maxsites: "",
      customer: "",
      useraccess: "",
      hideInfo: "d-none",
      customerData: [],
      userdata: [],
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }
  async handleDropdownChange(e) {
    console.log("handleDropdownChange " + e.target.value + " name " + e.target.id );
    if(e.target.id === "selectcustomer"){
      this.setState({ customer: e.target.value});
    }
    else{
      this.setState({ useraccess: e.target.value});
    }
  }

  async componentDidMount() {
    try {
      let res = await fetch("/isLoggedIn", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let result = await res.json();
      console.log("Got isLoggedIn resu1t: " + result.success);
      if (result && result.success) {
        
        UserStore.isLoggedIn = true;
        UserStore.loading = false;
        UserStore.username = result.username;
        UserStore.email = result.email;

        try {
          let res = await fetch("/getcustomers", {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: UserStore.username,
            
            }),
          });
          let result = await res.json();
          if (result && result.success) {
            this.setState({
              customerData: result.data,
              
            });
            
          }
        }
        catch (e) {
          
        }

        
      } else {
        UserStore.loading = false;
        UserStore.isLoggedIn = false;
        
      }
    } catch (e) {
      console.log("Got isLoggedIn catch " + e);
      UserStore.loading = false;
      UserStore.isLoggedIn = false;
      
    }
    try {
      let res = await fetch("/getusers", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
          
        });
        
      }
    }
    catch (e) {
      
    }
  }

  

  async doAssignUser() {
    console.log("doAssignUser");
    if(this.state.customer === "" || this.state.useraccess === "" ){
      this.setState({
        hideInfo: "",
        serverText: "Error: Please Choose Options"
      });  
      return;
    }
    try {
      let res = await fetch("/assignuser", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer: this.state.customer,
          useraccess: this.state.useraccess,
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.componentDidMount();
        this.setState({
          customerData: result.data
        });
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
  }

  async doAddServer() {
    console.log("doAddServer");
    if(this.state.serverurl === "" || this.state.maxsites === "" ){
      this.setState({
        hideInfo: "",
        serverText: "Error: All fields must be populated"
      });  
      return;
    }
    try {
      let res = await fetch("/addserver", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serverurl: this.state.serverurl,
          maxsites: this.state.maxsites,
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.componentDidMount();
        this.setState({
          hideInfo: "",
          serverText: "Server Registered"
        });
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
  }
  
  setInputValue(property, val) {
    val = val.trim();

    this.setState({
      [property]: val,
    });
  }
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
    
    let customerData = this.state.customerData.map((item) => (
      <tr><td>{item.serverurl}</td><td>{item.customerid}</td><td onClick={() => this.assignUser(item.customer)}>{item.useraccess?item.useraccess:"UnAssigned"}</td></tr>
      )); 
    let customerDataOptions = this.state.customerData.map((item) => (
      <option name="site" value={item.customerid}>{item.customerid}</option>
      
      ));  
    let userDataOptions = this.state.userdata.map((item) => item.username !== "admin" ? ( 
      <option name="user" value={item.username}>{item.username}</option>
    
      ):null);
      
    if(isLoggedIn && UserStore.username === "admin"){
      return (
      
        <React.Fragment>
          <div className="container mt-4">
            <div className="header-pearl">                           
              <h5>Customers</h5>
            </div>
            <div className="App body-pearl">
              <div className="pearl-center">
               <div><select id="selectcustomer" onChange={this.handleDropdownChange}>
                  <option value="">Choose Customer</option>
                  {customerDataOptions}
                </select></div> 
                <div><select id="selectuser" onChange={this.handleDropdownChange}>
                  <option value="">Choose User</option>
                  {userDataOptions}
                </select></div>
                  
                  
                  <SubmitButton
                    text="Assign User"
                    onClick={() => this.doAssignUser()}
                  />
        
                </div> 
              
                <div className={this.state.hideInfo}>
                    <h6>Server Details - {this.state.serverText}</h6>
                </div>
                <div className=" h-100 d-flex justify-content-center align-items-center">
                  <table className="entries"><tr><th>Server Url</th><th>Customer ID</th><th>User Access</th></tr>{customerData}</table>
                </div>
            </div>
          </div>
        </React.Fragment>
      );

    }
    else{
      return (
        <div className="container mt-4"><p>Invalid Permissions</p></div>
        
      );
    }

    
  }
}

export default observer(App);
