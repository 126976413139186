import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import InputField from "../components/InputField";
import SubmitButton from "../components/SubmitButton";
import "../pearllion.css";
class Monitor extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      devicename: "",
      hideInfo: "d-none",
      userdata: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  async componentDidMount() {
    console.log("componentDidMount: get users");
    try {
      let res = await fetch("/getmonitors", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
          
        });
        
      }
    }
    catch (e) {
      
    }
  }
  setInputValue(property, val) {
    val = val.trim();

    this.setState({
      [property]: val,
    });
  }

  async doQuery() {
    console.log("doQuery");
    
    try {
      let res = await fetch("/monitorquery", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          devicename: this.state.devicename,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
        });
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
  }

  async handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
   // console.log("handleInputChange: name " + name + " value " + value);
    try {
      let res = await fetch("/changemonitor", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          monitor: value,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
        });
               
        
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
    
  }
  
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
    
    let userData = this.state.userdata.map((item) => (
      <tr><td><input name={item.name} type="checkbox" checked={item.monitor==="true"} onChange={this.handleInputChange}/></td><td>{item.name}</td><td>{item.site}</td></tr>
      ));  
    
    
    if(isLoggedIn && UserStore.username === "admin"){
      return (
      
        <React.Fragment>
          <div className="container mt-4">
            <div className="header-pearl">                           
              <h5>Monitor Devices</h5>
            </div>
            <div className="App body-pearl">
              <div className="pearl-center">
                <div className={this.state.hideInfo}>
                    <h6>Monitor Changed</h6>
                </div>
                <div className="loginForm">
        
                  <InputField
                    type="text"
                    placeholder="Device Name"
                    value={this.state.devicename ? this.state.devicename : ""}
                    onChange={(val) => this.setInputValue("devicename", val)}
                  />
                  
                  <SubmitButton
                    text="Query"
                    onClick={() => this.doQuery()}
                  />
        
                </div> 
                <div className=" h-100 d-flex justify-content-center align-items-center">
                  <table className="entries"><tr><th>Monitor</th><th>Name</th><th>Site</th></tr>{userData}</table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );

    }
    else{
      return (
        <div className="container mt-4"><p>Invalid Permissions</p></div>
        
      );
    }

    
  }
}

export default observer(Monitor);
