import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import LoginForm from "./LoginForm";

class LoginModal extends Component {
  render() {
    return (
      <Modal show={this.props.showForm} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm
            handleClose={this.props.onClose}
            handlePasswordReset={this.props.onPasswordReset}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LoginModal;
