import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import "../pearllion.css";
class AppBlock extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      blockText: "",
      blockData: [],
    };
    
  }
  
  async componentDidMount() {
    console.log("componentDidMount: get blocks username " + UserStore.username);
    try {
      let res = await fetch("/getallblocks", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          blockData: result.data,
          
        });
        
      }
    }
    catch (e) {
      
    }
    
  }
  

  
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
               
    let blockData = this.state.blockData.map((item) => (
      <tr><td>{item.name}</td><td>{item.sitename}</td><td>{item.siteurl}:{item.port}</td><td>{item.extension}</td><td>{item.password}</td></tr>
      )); 
      
    if(isLoggedIn && UserStore.username === "admin"){
      return (
      
        <React.Fragment>
          <div className="container mt-4">
            <div className="header-pearl">                           
              <h5>Blocks</h5>
            </div>
            <div className="App body-pearl">
              <div className="pearl-center">
               
                <div className=" h-100 d-flex justify-content-center align-items-center">
                  <table className="entries"><tr><th>Block Name</th><th>Site Name</th><th>Sip Url</th><th>User</th><th>Password</th></tr>{blockData}</table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );

    }
    else{
      return (
        <div className="container mt-4"><p>Invalid Permissions</p></div>
        
      );
    }

    
  }
}

export default observer(AppBlock);
