import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import "../pearllion.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      hideSite: "d-none",
      hideBlock: "d-none",
      hideSiteInfo: "d-none",
      singleSiteData: [],
      blockData: [],
      customerData: [],
      siteData: [],
      mobileData: [],
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }
  
  async handleDropdownChange(e) {
    console.log("handleDropdownChange " + e.target.value + " name " + e.target.id );
    if(e.target.id === "selectcustomer"){
      try {
        let res = await fetch("/getsites", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.state.username,
            customerid: e.target.value,
          
          }),
        });
        let result = await res.json();
        if (result && result.success) {
          this.setState({
            siteData: result.data,
            
          });
          
        }
      }
      catch (e) {
        console.log(e);
        
      }
      this.setState({ hideSite: "pearl-center"});
    }
    else if(e.target.id === "selectsite"){
      try {

        let res = await fetch("/getsiteblocks", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sitename: e.target.value,
          
          }),
        });
        let result = await res.json();
        if (result && result.success) {
          this.setState({
            blockData: result.data,
            singleSiteData: result.sitedata,
            hideBlock: "pearl-center"
            
          });
        }
      }
      catch (e) {
        console.log(e);
        
      }
      
    }
    else if(e.target.id === "selectblock"){
      try {

        let res = await fetch("/querymobiles", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            blockname: e.target.value,
          
          }),
        });
        let result = await res.json();
        if (result && result.success) {
          this.setState({
            mobileData: result.data,
            hideSiteInfo: "pearl-center"
            
          });
        }
      }
      catch (e) {
        console.log(e);
        
      }
    }
  }

  async componentDidMount() {
    try {
      let res = await fetch("/isLoggedIn", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let result = await res.json();
      console.log("Got isLoggedIn resu1t: " + result.success);
      if (result && result.success) {
        
        UserStore.isLoggedIn = true;
        UserStore.loading = false;
        UserStore.username = result.username;
        UserStore.email = result.email;
        this.setState({
          username: result.username,
          
        });
        try {
          let res = await fetch("/getcustomers", {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: UserStore.username,
            
            }),
          });
          let result = await res.json();
          if (result && result.success) {
            this.setState({
              customerData: result.data,
              
            });
            if(UserStore.username !== "admin"){
              this.setState({
                siteData: result.sitedata,
                
              });
              this.setState({ hideSite: "pearl-center"});    
            }
            
          }
        }
        catch (e) {
          
        }

        
      } else {
        UserStore.loading = false;
        UserStore.isLoggedIn = false;
        
      }
    } catch (e) {
      console.log("Got isLoggedIn catch " + e);
      UserStore.loading = false;
      UserStore.isLoggedIn = false;
      
    }
  }
  
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
    
    function getUsernameGreeting() {
      let greeting = "";
      if (isLoggedIn) {
        greeting = "Hi " + UserStore.username;
      }
      return greeting;
    }
    let customerDataOptions = this.state.customerData.map((item) => (
      <option name="customer" value={item.customerid}>{item.customerid}</option>
      
      )); 
    let siteDataOptions = this.state.siteData.map((item) => (
      <option name="site" value={item.sitename}>{item.sitename}</option>
      
      ));
    let blockDataOptions = this.state.blockData.map((item) => (
      <option name="block" value={item.blockname}>{item.blockname}</option>
      
      ));
    let singleSiteData = this.state.singleSiteData.map((item) => (
          <tr><td>{item.siteurl}:{item.port}</td><td>{item.extension}</td><td>{item.password}</td></tr>
          
      ));
    let mobileSiteData = this.state.mobileData.map((item) => (
      <tr><td>{item.siteurl}</td><td>{item.mobile}</td><td>{item.password}</td><td>{item.email}</td></tr>
       
    ));  
    
      
    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className="App body-pearl">
            <div className="header-pearl">
              <h3>Welcome to Pearl Lion Connect</h3>
              <p>{getUsernameGreeting()}</p>
            </div>
            
            <div className={UserStore.username==="admin"?"pearl-center":"d-none"}>
              <div><select id="selectcustomer" onChange={this.handleDropdownChange}>
                  <option value="">Choose Customer</option>
                  {customerDataOptions}
                </select>
              </div>
            </div>
            <div className={this.state.hideSite} >
            <div><select id="selectsite" onChange={this.handleDropdownChange}>
                  <option value="">Choose Site</option>
                  {siteDataOptions}
                </select>
              </div>

            </div>
            <div className={this.state.hideBlock} >
            <div><select id="selectblock" onChange={this.handleDropdownChange}>
                  <option value="">Choose Block</option>
                  {blockDataOptions}
                </select>
              </div>

            </div>
            <div className={this.state.hideSiteInfo}>
            <br/><p>Site Credentials</p>
              <div className=" h-100 d-flex justify-content-center align-items-center">
                <table className="entries"><tr><th>Sip URL</th><th>User</th><th>Password</th></tr>{singleSiteData}</table>
              </div>
              <br/><p>Mobile Credentials</p>
              <div className=" h-100 d-flex justify-content-center align-items-center">
                <table className="entries"><tr><th>Sip URL</th><th>Mobile</th><th>Password</th><th>Email</th></tr>{mobileSiteData}</table>
              </div>
            </div>
            
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default observer(App);
