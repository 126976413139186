import { extendObservable } from "mobx";

class UserStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      isLoggedIn: false,
      firstname: "",
      lastname: "",
      username: "",
      email: "",
    });
  }
}

export default new UserStore();
