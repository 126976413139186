import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import InputField from "../components/InputField";
import SubmitButton from "../components/SubmitButton";
import "../pearllion.css";
class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      serverText: "",
      username: "",
      password: "",
      serverurl: "",
      maxsites: "",
      hideInfo: "d-none",
      serverData: []
    };
  }
  
  async componentDidMount() {
    console.log("componentDidMount: get servers");
    try {
      let res = await fetch("/getserver", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          serverData: result.data,
          
        });
        
      }
    }
    catch (e) {
      
    }
  }

  async doAddServer() {
    console.log("doAddServer");
    if(this.state.serverurl === "" || this.state.maxsites === "" ){
      this.setState({
        hideInfo: "",
        serverText: "Error: All fields must be populated"
      });  
      return;
    }
    try {
      let res = await fetch("/addserver", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serverurl: this.state.serverurl,
          maxsites: this.state.maxsites,
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.componentDidMount();
        this.setState({
          hideInfo: "",
          serverText: "Server Registered"
        });
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
  }
  
  setInputValue(property, val) {
    val = val.trim();

    this.setState({
      [property]: val,
    });
  }
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
    
    let serverData = this.state.serverData.map((item) => (
      <tr><td>{item.url}</td><td>{item.maxnumsites}</td><td>{item.currentnumsites}</td></tr>
      ));  
    
    
    if(isLoggedIn && UserStore.username === "admin"){
      return (
      
        <React.Fragment>
          <div className="container mt-4">
            <div className="header-pearl">                           
              <h5>Servers</h5>
            </div>
            <div className="App body-pearl">
              <div className="pearl-center">
                <div className="loginForm">
        
                  <InputField
                    type="text"
                    placeholder="Server URL e.g. sip1.pearllion.com"
                    value={this.state.serverurl ? this.state.serverurl : ""}
                    onChange={(val) => this.setInputValue("serverurl", val)}
                  />
                  <InputField
                    type="text"
                    placeholder="Max Number Of Sites Per Server e.g. 1"
                    value={this.state.maxsites ? this.state.maxsites : ""}
                    onChange={(val) => this.setInputValue("maxsites", val)}
                  />
                  
                  <SubmitButton
                    text="Register Server"
                    onClick={() => this.doAddServer()}
                  />
        
                </div> 
              
                <div className={this.state.hideInfo}>
                    <h6>Server Details - {this.state.serverText}</h6>
                </div>
                <div className=" h-100 d-flex justify-content-center align-items-center">
                  <table className="entries"><tr><th>Server Url</th><th>Maximum No. Sites</th><th>Current No. Sites</th></tr>{serverData}</table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );

    }
    else{
      return (
        <div className="container mt-4"><p>Invalid Permissions</p></div>
        
      );
    }

    
  }
}

export default observer(App);
