import React, { Component } from "react";
import { observer } from "mobx-react";
import LogoutButton from "./LogoutButton";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import mainLogo from "../assets/doorentry.png";
import UserStore from "../components/stores/userStore";
import LoginModal from "../components/loginModal";
class NavbarBootstrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      modalType: 0,
      showLogIn: false,
      showTrialInfo: false,
      trialShown: false,
      message: "",
    };
  }

  closeFreeTrialInfo = () => {
    this.setState({ showTrialInfo: false });
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collID) => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: "" });
  };

  redirectPasswordReset = () => {
    this.setState({ showLogIn: false });
    window.location.href = "/passwordReset";
  };

  closeLoginForm = () => {
    this.setState({ showLogIn: false });
  };
  openLoginForm = () => {
    this.setState({ showLogIn: true });
  };
  render() {
    const logoStyle = {
      width: "2.2em",
      height: "auto",
    };
    
    return (
      <div>
        <div>
          <LoginModal
            showForm={this.state.showLogIn}
            onClose={this.closeLoginForm}
            onPasswordReset={this.redirectPasswordReset}
          />
        </div>
        <div>
          <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
            <Navbar.Brand href="/">
              <img src={mainLogo} style={logoStyle} alt="Pearl Lion Connect" />{" "}
              <span className="pearl-primary-text">Pearl Lion Connect</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-5">
                <Nav.Link href="/">
                  <span className="pearl-primary-text">Home</span>
                </Nav.Link>
              </Nav>
              
              {UserStore.isLoggedIn && UserStore.username === "admin" ? <Nav className="mr-auto ">
                <Nav.Link href="/User">
                  <span className="pearl-primary-text">Users</span>
                </Nav.Link>
                <Nav.Link href="/Server">
                  <span className="pearl-primary-text">Servers</span>
                </Nav.Link>
                <Nav.Link href="/Customer">
                  <span className="pearl-primary-text">Customers</span>
                </Nav.Link>
                <Nav.Link href="/Site">
                  <span className="pearl-primary-text">Sites</span>
                </Nav.Link>
                <Nav.Link href="/Monitor">
                  <span className="pearl-primary-text">Monitor</span>
                </Nav.Link>
                </Nav> : ""
                        
              }
              
              
              <Nav className="ml-auto mr-5 pr-4">
                <NavDropdown
                  title={
                    <div style={{ display: "inline-block" }}>
                      <i className="fa fa-user"></i>

                      <span className="pearl-primary-text">
                        {UserStore.isLoggedIn
                          ? " " + UserStore.username
                          : " User"}
                      </span>
                      {"   "}
                    </div>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item>
                    <LogoutButton
                      showForm={this.openLoginForm}
                      onClose={this.closeLoginForm}
                    />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

export default observer(NavbarBootstrap);
