import React, { Component } from "react";
import NavBar from "./components/navbarBootstrap";
import Message from "./components/Message";
import UserStore from "./components/stores/userStore";
import { observer } from "mobx-react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./pearllion.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  async componentDidMount() {
    try {
      let res = await fetch("/isLoggedIn", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let result = await res.json();
      console.log("Got isLoggedIn resu1t: " + result.success);
      if (result && result.success) {
        
        UserStore.isLoggedIn = true;
        UserStore.loading = false;
        UserStore.username = result.username;
        UserStore.email = result.email;
        
      } else {
        UserStore.loading = false;
        UserStore.isLoggedIn = false;
        
      }
    } catch (e) {
      console.log("Got isLoggedIn catch " + e);
      UserStore.loading = false;
      UserStore.isLoggedIn = false;
      
    }
  }

  render() {
    return (
      <Router>
        <div className="App body-pearl">
          <div>
            <NavBar />
          </div>
          <div>
            {this.state.message ? <Message msg={this.state.message} /> : null}
          </div>
          <Routes />
        </div>
      </Router>
    );
  }
}

export default observer(App);
