import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import UsersPage from "./pages/UserPage";
import ServersPage from "./pages/ServerPage";
import SitesPage from "./pages/SitePage";
import CustomersPage from "./pages/CustomerPage";
import BlocksPage from "./pages/BlockPage";
import MonitorPage from "./pages/MonitorPage";
class Routes extends React.Component {
  render() {
    return (
      <Switch>
       <Route exact path="/" component={HomePage} />
       <Route exact path="/Site" component={SitesPage} />
       <Route exact path="/Customer" component={CustomersPage} />
       <Route exact path="/Block" component={BlocksPage} />
       <Route exact path="/Server" component={ServersPage} />
       <Route exact path="/User" component={UsersPage} />
       <Route exact path="/Monitor" component={MonitorPage} />

        <Route
          render={function () {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
