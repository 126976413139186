import React, { Component } from "react";
import { observer } from "mobx-react";
import UserStore from "../components/stores/userStore";
import InputField from "../components/InputField";
import SubmitButton from "../components/SubmitButton";
import "../pearllion.css";
class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      passwordText: "",
      username: "",
      email: "",
      password: "",
      hideInfo: "d-none",
      userdata: []
    };
  }
  
  async componentDidMount() {
    console.log("componentDidMount: get users");
    try {
      let res = await fetch("/getusers", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
          
        });
        
      }
    }
    catch (e) {
      
    }
  }

  async doAddUser() {
    console.log("doAddUser");
    if(this.state.username === ""){
      alert("User Is Empty");
      return;
    }
    try {
      let res = await fetch("/adduser", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
          email: this.state.email,
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        console.log("adduser Username", result.username);
        console.log("adduser Password", result.password);
        this.setState({
          hideInfo: "",
          userdata: result.data,
          username: result.username,
          password: result.password,

          passwordText: "Password Created"
        });
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
  }
  async deleteUser(name){
    try {
      let res = await fetch("/deleteuser", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: name,
          
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          userdata: result.data,
        });
        alert(result.msg);
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
   
  }
  async resetPassword(name){
    try {
      let res = await fetch("/resetpwd", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: name,
          
        
        }),
      });
      let result = await res.json();
      if (result && result.success) {
        this.setState({
          hideInfo: "",
          username: result.username,
          password: result.password,

          passwordText: "Password Reset"
        });
        
      } else if (result && result.success === false) {
        
        alert(result.msg);
      }
    } catch (e) {
      
    }
    
  }
  setInputValue(property, val) {
    val = val.trim();

    this.setState({
      [property]: val,
    });
  }
   
  render() {
    const isLoggedIn = UserStore.isLoggedIn;
    
    let userData = this.state.userdata.map((item) => item.username !== "admin" ? ( 
      
      
      <tr><td>{item.username}</td><td onClick={() => this.deleteUser(item.username)}><a className="fa fa-trash" title="Delete" style={{ color:"red" }}></a></td>
      <td onClick={() => this.resetPassword(item.username)}><a className="fa fa-key" title="Reset Password" style={{ color:"red" }}></a></td></tr>
      
      ):null); 

    if(isLoggedIn && UserStore.username === "admin"){
      return (
      
        <React.Fragment>
          <div className="container mt-4">
            <div className="header-pearl">                           
              <h5>Users</h5>
            </div>
            <div className="App body-pearl">
              <div className="pearl-center">
                <div className="loginForm">
        
                  <InputField
                    type="text"
                    placeholder="Username"
                    value={this.state.username ? this.state.username : ""}
                    onChange={(val) => this.setInputValue("username", val)}
                  />
                                    
                  <SubmitButton
                    text="Add User"
                    onClick={() => this.doAddUser()}
                  />
        
                </div> 
              
                <div className={this.state.hideInfo}>
                    <h6>User Details - {this.state.passwordText}</h6>
                    <p className="important">!! Take a copy, passwords will only be displayed once !!</p>
                    <p>User created username {this.state.username} with password {this.state.password}</p>
                </div>
                <div className=" h-100 d-flex justify-content-center align-items-center">
                  <table className="entries"><tr><th>User Name</th><th>Delete</th><th>Reset Password</th></tr>{userData}</table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );

    }
    else{
      return (
        <div className="container mt-4"><p>Invalid Permissions</p></div>
        
      );
    }

    
  }
}

export default observer(App);
